<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="light-blue"
      icon="mdi-account-tie"
      title="Categorias"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="categoriaslist"
        :headers="headers"
        :items="categorias"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              small
              class="mb-2"
              @click="addCategorias()"
            >
              <v-icon
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.nombre_padre }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <addCategorias
        :edited-item="datos"
        :edited-index="editedIndex"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import addCategorias from './addCategorias.vue'
  export default {
    name: 'CategoriasTable',
    components: {
      addCategorias,
    },
    data: vm => {
      return {
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        datos: {},
        editedIndex: -1,
        headers: [
          {
            text: 'Nombre',
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Padre',
            align: 'left',
            sortable: true,
            value: 'nombre_padre',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
      }
    },
    computed: {
      categoriaslist: {
        get () {
          return this.$store.state.categorias.categoriaslist
        },
      },
      categorias: {
        get () {
          return this.$store.state.categorias.categorias
        },
        set (value) {
          return this.$store.commit('categorias/setCategorias', value)
        },
      },
      loading: {
        get () {
          return this.$store.state.categorias.loading
        },
      },
    },
    mounted () {
      this.$store.dispatch('categorias/getCategorias')
    },
    methods: {
      addCategorias () {
        this.datos = {}
        this.editedIndex = -1
        this.$store.commit('categorias/setRegister', true)
      },
      editItem (item) {
        this.editedIndex = this.categorias.indexOf(item)
        this.datos = Object.assign({}, item)
        this.$store.commit('categorias/setRegister', true)
      },
      deleteItem (item) {
        this.$confirm('Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('categorias/deleteCategoria', item.id)
                .then((response) => {
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
